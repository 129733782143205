import {
  defineComponent, ref, unref, watch,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import {
  SfInput,
  SfButton,
  SfLoader,
  SfLink,
} from '@storefront-ui/vue';
import { required, length } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

import RsgMaskedInput from '~/components/MaskedInput';
import { useBem } from '~/composables';

import { SendCodeFormFields } from '../../types';

extend('length', {
  ...length,
  message: 'Неверный формат телефона',
});

extend('required', {
  ...required,
  message: 'Это поле обязательно',
});

// @vue/component
export default defineComponent({
  name: 'RsgSendCodeForm',

  components: {
    SfInput,
    SfLink,
    SfButton,
    SfLoader,
    ValidationObserver,
    ValidationProvider,
    RsgMaskedInput,
  },

  props: {
    form: {
      type: Object as PropType<SendCodeFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const b = useBem();

    const formInternal = ref<SendCodeFormFields>(null);

    watch(
      () => props.form,
      (value) => {
        formInternal.value = {
          ...value,
        };
      },
      {
        immediate: true,
        deep: true,
      },
    );

    const onFormSubmit = () => {
      emit('submit', unref(formInternal));
    };

    return {
      b,

      formInternal,

      onFormSubmit,
    };
  },
});
