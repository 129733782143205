import {
  computed, defineComponent, reactive, ref, unref, useContext, useRoute, useRouter,
} from '@nuxtjs/composition-api';
import { SfButton, SfCircleIcon, SfSidebar } from '@storefront-ui/vue';

import { useBem, useCart, useGTM } from '~/composables';

import SvgImage from '~/components/General/SvgImage.vue';

import { useUser } from '~/modules/customer/composables/useUser';

import RsgLoginByCodeForm from './children/login-by-code-form';
import RsgSendCodeForm from './children/send-code-form';
import RsgSignUpForm from './children/sign-up-form';

import {
  LoginByCodeFormErrors, LoginByCodeFormFields, SendCodeFormFields, SignUpFormErrors, SignUpFormFields,
} from './types';

export const NEW_CODE_TIMEOUT = 60 * 1000;

// @vue/component
export default defineComponent({
  name: 'RsgLoginByCodeModal',

  components: {
    SfButton,
    SfCircleIcon,
    SfSidebar,
    SvgImage,
    RsgLoginByCodeForm,
    RsgSendCodeForm,
    RsgSignUpForm,
  },

  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { emit }) {
    const b = useBem();

    const router = useRouter();
    const route = useRoute();
    const { app } = useContext();

    const {
      load: loadUser,
      loading,
      loginByCode,
      sendCode,
      updateUser,
      error: userError,
      user,
    } = useUser();

    const { trackEvent } = useGTM();

    const { load: loadCart } = useCart();

    const step = ref(0);
    const showBack = computed(() => unref(step) === 1);

    const sendCodeForm = ref<SendCodeFormFields>({
      phone: '',
    });

    const loginByCodeForm = ref<LoginByCodeFormFields>({
      code: '',
    });
    const loginByCodeFormErrors = ref<LoginByCodeFormErrors>({
      code: null,
    });

    const signUpForm = ref<SignUpFormFields>({
      firstname: '',
      lastname: '',
      email: '',
      isSubscribed: false,
      // privacyPolicyAccepted: false,
    });
    const signUpFormErrors = ref<SignUpFormErrors>({
      firstname: null,
      lastname: null,
      email: null,
    });

    const timers = reactive(new Map());
    // Костыль для обновления timer
    const timerChangesCounter = ref(0);
    const timer = computed(() => unref(timerChangesCounter) && timers.get(unref(sendCodeForm).phone));

    const tryToRedirectCustomer = async () => {
      const currentMatchedRoutes = computed(() => route.value.matched);
      await (currentMatchedRoutes.value[currentMatchedRoutes.value.length - 1].name !== app.localeRoute({ name: 'checkout' }).name ? router.push(app.localeRoute({ name: 'customer-my-profile' })) : loadUser());
    };

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));

    const getRecaptchaInfo = async (isRecaptchaOn: boolean) : Promise<{ token: string | null, cleanup: () => void }> => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return { token: await $recaptcha.getResponse(), cleanup: () => { $recaptcha.reset(); } };
      }
      return { token: null, cleanup: () => {} };
    };

    const onSendCodeFormSubmit = async (form: SendCodeFormFields) => {
      sendCodeForm.value = form;

      const { phone } = unref(sendCodeForm);

      const now = Date.now();

      if (timers.get(phone) >= now) {
        step.value += 1;
        return;
      }

      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await sendCode({
        phone,
        ...(token ? { recaptchaToken: token } : {}),
      });
      cleanup();

      if (!userError.value.sendCode) {
        timers.set(phone, now + NEW_CODE_TIMEOUT);
        timerChangesCounter.value++;
        step.value += 1;
      } else if (userError.value.sendCode.message === 'Wait 1 minutes to next sending') {
        timers.set(phone, now + NEW_CODE_TIMEOUT);
        timerChangesCounter.value++;
        step.value += 1;
      }
    };

    const onLoginByCodeFormSubmit = async (form: LoginByCodeFormFields) => {
      loginByCodeForm.value = form;

      const { phone } = unref(sendCodeForm);

      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await loginByCode({
        phone,
        code: form.code,
        ...(token ? { recaptchaToken: token } : {}),
      });
      cleanup();

      if (!userError.value.loginByCode) {
        await Promise.all([loadUser(), loadCart()]);

        if (unref(user).requiredFieldsFilled) {
          step.value = 0;
          trackEvent('login');
          emit('close');

          await tryToRedirectCustomer();
        }

        signUpForm.value.firstname = unref(user).firstname || '';
        signUpForm.value.lastname = unref(user).lastname || '';
        signUpForm.value.email = unref(user).email || '';

        step.value += 1;
      } else {
        loginByCodeFormErrors.value.code = userError.value.loginByCode.message;
      }
    };

    const onLoginByCodeFormGetNewCode = async () => {
      const { phone } = unref(sendCodeForm);

      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await sendCode({
        phone,
        ...(token ? { recaptchaToken: token } : {}),
      });
      cleanup();

      if (!userError.value.sendCode) {
        timers.set(phone, Date.now() + NEW_CODE_TIMEOUT);
        timerChangesCounter.value++;
      }
    };

    const onLoginByCodeFormResetError = () => {
      loginByCodeFormErrors.value.code = null;
    };

    const onSignUpFormSubmit = async (form: SignUpFormFields) => {
      signUpForm.value = form;

      const {
        firstname,
        lastname,
        email,
        isSubscribed,
        // privacyPolicyAccepted,
      } = unref(signUpForm);

      await updateUser({
        user: {
          firstname,
          lastname,
          email,
          // ...(privacyPolicyAccepted ? { privacyPolicyAccepted } : {}),
          ...(isSubscribed ? { is_subscribed: isSubscribed } : {}),
        },
      });

      if (!userError.value.updateUser) {
        step.value = 0;
        trackEvent('sign_up');
        emit('close');
        await tryToRedirectCustomer();
      } else {
        signUpFormErrors.value.email = (userError.value.changePassword || userError.value.updateUser).toString();
      }
    };

    const onSignUpFormResetError = (key: string) => {
      signUpFormErrors.value[key] = null;
    };

    const onBackClick = () => {
      step.value = Math.max(0, unref(step) - 1);
    };

    const onSidebarClose = () => {
      emit('close');
    };

    return {
      b,

      step,
      showBack,
      timer,

      userError,
      loading,

      sendCodeForm,

      loginByCodeForm,
      loginByCodeFormErrors,

      signUpForm,
      signUpFormErrors,

      onSendCodeFormSubmit,
      onLoginByCodeFormSubmit,
      onLoginByCodeFormGetNewCode,
      onLoginByCodeFormResetError,
      onSignUpFormSubmit,
      onSignUpFormResetError,
      onBackClick,
      onSidebarClose,
    };
  },
});
