import {
  computed,
  defineComponent, ref, unref, watch,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import {
  SfButton,
  SfLoader,
} from '@storefront-ui/vue';
import IMask from 'imask';
import { length, required } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

import RsgMaskedInput from '~/components/MaskedInput';
import { useBem } from '~/composables';

import { LoginByCodeFormErrors, LoginByCodeFormFields } from '../../types';

extend('length', {
  ...length,
  message: 'Неверный формат кода',
});

extend('required', {
  ...required,
  message: 'Это поле обязательно',
});

// @vue/component
export default defineComponent({
  name: 'RsgLoginByCodeForm',

  components: {
    SfButton,
    SfLoader,
    ValidationObserver,
    ValidationProvider,
    RsgMaskedInput,
  },

  props: {
    form: {
      type: Object as PropType<LoginByCodeFormFields>,
      required: true,
    },
    formErrors: {
      type: Object as PropType<LoginByCodeFormErrors>,
      default: null,
    },
    phone: {
      type: String,
      required: true,
    },
    timer: {
      type: Number,
      default: Date.now(),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const b = useBem();

    const formInternal = ref<LoginByCodeFormFields>(null);

    const phoneMasked = IMask.createMask({
      mask: '+7 (000) 000 00 00',
    });

    const phoneInternal = computed(() => {
      phoneMasked.resolve(props.phone);

      return phoneMasked.value;
    });

    const timerInterval = ref(null);
    const timerInternal = ref(Math.max(0, Math.round((props.timer - Date.now()) / 1000)));
    const showTimer = computed(() => timerInternal.value > 0);

    const minutes = computed(() => {
      const minutesInternal = Math.floor(timerInternal.value / 60);

      return minutesInternal >= 10 ? minutesInternal : `0${minutesInternal}`;
    });

    const seconds = computed(() => {
      const secondsInternal = timerInternal.value % 60;

      return secondsInternal >= 10 ? secondsInternal : `0${secondsInternal}`;
    });

    watch(
      () => props.timer,
      (value) => {
        if (timerInterval.value) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          clearInterval(timerInterval.value);
        }

        timerInterval.value = setInterval(() => {
          const diff = Math.max(0, Math.round((value - Date.now()) / 1000));

          if (diff < 0 && timerInterval.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            clearInterval(timerInterval.value);
          }

          timerInternal.value = diff;
        }, 1000);
      },
      {
        immediate: true,
      },
    );

    watch(
      () => props.form,
      (value) => {
        formInternal.value = {
          ...value,
        };
      },
      {
        immediate: true,
        deep: true,
      },
    );

    watch(
      () => formInternal.value.code,
      () => {
        emit('reset-error');
      },
    );

    const onNewCodeButtonClick = () => {
      emit('get-new-code');
    };

    const onFormSubmit = () => {
      emit('submit', unref(formInternal));
    };

    return {
      b,

      formInternal,

      phoneInternal,

      showTimer,
      minutes,
      seconds,

      onNewCodeButtonClick,
      onFormSubmit,
    };
  },
});
