var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.b()},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{class:_vm.b('form'),on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.onFormSubmit(); })}}},[_c('header',{class:_vm.b('header')},[_c('h2',{class:_vm.b('title')},[_vm._v("\n          регистрация\n        ")]),_vm._v(" "),_c('p',{class:_vm.b('phone')},[_vm._v("\n          "+_vm._s(_vm.phoneInternal)+"\n        ")]),_vm._v(" "),_c('p',{class:_vm.b('subtitle')},[_vm._v("\n          Заполните данные для оформления заказов\n        ")])]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|min:1","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsfValidableInput',{class:_vm.b('input'),attrs:{"valid":!_vm.formErrors.firstname && !errors[0],"error-message":_vm.$t(_vm.formErrors.firstname) || _vm.$t(errors[0]),"name":"firstname","placeholder":"Имя"},model:{value:(_vm.formInternal.firstname),callback:function ($$v) {_vm.$set(_vm.formInternal, "firstname", $$v)},expression:"formInternal.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|min:1","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsfValidableInput',{class:_vm.b('input'),attrs:{"valid":!_vm.formErrors.lastname && !errors[0],"error-message":_vm.$t(_vm.formErrors.lastname) || _vm.$t(errors[0]),"name":"lastname","placeholder":"Фамилия"},model:{value:(_vm.formInternal.lastname),callback:function ($$v) {_vm.$set(_vm.formInternal, "lastname", $$v)},expression:"formInternal.lastname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsfValidableInput',{class:_vm.b('input'),attrs:{"valid":!_vm.formErrors.email && !errors[0],"error-message":_vm.$t(_vm.formErrors.email) || _vm.$t(errors[0]),"name":"email","placeholder":"E-mail"},model:{value:(_vm.formInternal.email),callback:function ($$v) {_vm.$set(_vm.formInternal, "email", $$v)},expression:"formInternal.email"}})]}}],null,true)}),_vm._v(" "),_c('div',{class:_vm.b('checkboxes-field')},[_c('SfCheckbox',{class:_vm.b('checkbox'),attrs:{"name":"isAcceptPolicy"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{class:_vm.b('checkbox-label')},[_vm._v("\n              Я соглашаюсь с\n              "),_c('SfLink',{class:_vm.b('checkbox-label-link'),attrs:{"target":"_blank","link":"/privacypolicy"}},[_vm._v("\n                политикой обработки персональных данных\n              ")])],1)]},proxy:true}],null,true),model:{value:(_vm.formInternal.privacyPolicyAccepted),callback:function ($$v) {_vm.$set(_vm.formInternal, "privacyPolicyAccepted", $$v)},expression:"formInternal.privacyPolicyAccepted"}}),_vm._v(" "),_c('SfCheckbox',{class:_vm.b('checkbox'),attrs:{"name":"isSubscribed"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{class:_vm.b('checkbox-label')},[_vm._v("\n              Я хочу получать новости о скидках и новинках\n            ")])]},proxy:true}],null,true),model:{value:(_vm.formInternal.isSubscribed),callback:function ($$v) {_vm.$set(_vm.formInternal, "isSubscribed", $$v)},expression:"formInternal.isSubscribed"}})],1),_vm._v(" "),_c('SfButton',{class:[_vm.b('submit'), 'sf-button--full-width'],attrs:{"type":"submit","disabled":_vm.loading || invalid || !_vm.formInternal.privacyPolicyAccepted}},[_vm._v("\n        сохранить\n      ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }