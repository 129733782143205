import {
  computed,
  defineComponent,
  ref,
  unref,
  watch,
} from '@nuxtjs/composition-api';
import VsfValidableInput from '~/components/VsfValidableInput.vue';
import VsfValidableMaskedInput from '~/components/VsfValidableMaskedInput.vue';
import type { PropType } from '@nuxtjs/composition-api';
import {
  SfButton,
  SfLoader,
  SfCheckbox,
  SfLink,
} from '@storefront-ui/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import IMask from 'imask';
import { email, required, min } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

import { useBem } from '~/composables';

import { SignUpFormFields, SignUpFormErrors } from '../../types';

extend('email', {
  ...email,
  message: 'Неверный формат e-mail',
});

extend('required', {
  ...required,
  message: 'Это поле обязательно',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

// @vue/component
export default defineComponent({
  name: 'RsgSignUpForm',

  components: {
    SfCheckbox,
    SfButton,
    SfLoader,
    SfLink,
    ValidationObserver,
    ValidationProvider,
    VsfValidableInput,
    VsfValidableMaskedInput,
  },

  props: {
    form: {
      type: Object as PropType<SignUpFormFields>,
      required: true,
    },
    formErrors: {
      type: Object as PropType<SignUpFormErrors>,
      default: null,
    },
    phone: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const b = useBem();

    const formInternal = ref<SignUpFormFields>(null);

    const phoneMasked = IMask.createMask({
      mask: '+7 (000) 000 00 00',
    });

    const phoneInternal = computed(() => {
      phoneMasked.resolve(props.phone);

      return phoneMasked.value;
    });

    watch(
      () => props.form,
      (value) => {
        formInternal.value = {
          ...value,
        };
      },
      {
        immediate: true,
        deep: true,
      },
    );

    watch(
      () => formInternal.value,
      () => {
        if (props.formErrors.email) {
          emit('reset-error', 'email');
        }
      },
      { deep: true },
    );

    const onFormSubmit = () => {
      emit('submit', unref(formInternal));
    };

    return {
      b,

      formInternal,

      phoneInternal,

      onFormSubmit,
    };
  },
});
