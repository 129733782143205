




















import {
  computed, defineComponent, ref,
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';

export default defineComponent({
  name: 'VsfValidableInput',
  components: {
    SfInput,
  },

  props: {
    value: { type: [String, Number], default: '' },
    label: { type: String, default: '' },
    type: { type: String, default: 'text' },
    required: {
      type: Boolean,
      default: false,
      description: 'Native input required attribute',
    },
    disabled: {
      type: Boolean,
      default: false,
      description: 'Native input disabled attribute',
    },
    hasShowPassword: {
      type: Boolean,
      default: false,
    },
    placeholder: { type: String, default: '' },
    name: { type: String, default: '' },
    errorMessage: { type: String, default: '' },
    valid: { type: Boolean, default: false },
    isDirty: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },

  emits: ['update:modelValue', 'input', 'change'],

  setup(props, { emit }) {
    const isDirty = ref(false);
    const computedValid = computed(() => (isDirty.value ? props.valid : true));
    const computedErrorMessage = computed(() => (isDirty.value ? props.errorMessage : ''));

    const setInputAsDirty = () => {
      if (!isDirty.value) {
        isDirty.value = true;
      }
    };

    const onFocus = () => {
      if (isDirty.value) {
        return;
      }

      if (props.value) {
        isDirty.value = true;
      }
    };

    const onInput = (e) => {
      emit('update:modelValue', e);
      emit('input', e);

      setInputAsDirty();
    };

    const onChange = (e) => {
      emit('change', e);
    };

    return {
      computedValid,
      computedErrorMessage,

      onInput,
      onFocus,
      onChange,
    };
  },
});
