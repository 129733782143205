import { SfButton, SfIcon } from '@storefront-ui/vue';
import { focus, willChange } from '@storefront-ui/vue/src/utilities/directives';
import { IMaskComponent } from 'vue-imask';

export default {
  name: 'RsgMaskedInput',
  directives: {
    focus,
    willChange,
  },
  components: {
    SfIcon,
    SfButton,
    IMaskComponent,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    icon: {
      type: Object,
      default: () => ({ icon: '' }),
    },
    valid: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
      description: 'Native input required attribute',
    },
    disabled: {
      type: Boolean,
      default: false,
      description: 'Native input disabled attribute',
    },
    hasShowPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPasswordVisible: false,
      inputType: '',
      isNumberTypeSafari: false,
    };
  },
  computed: {
    isPassword() {
      return this.type === 'password' && this.hasShowPassword;
    },
    nameWithoutWhitespace() {
      return this.name.replace(/\s/g, '');
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(type) {
        let inputType = type;
        // Safari has bug for number input
        if (typeof window !== 'undefined' || typeof document !== 'undefined') {
          const ua = navigator.userAgent.toLocaleLowerCase();
          if (
            ua.includes('safari')
            && !ua.includes('chrome')
            && type === 'number'
          ) {
            this.isNumberTypeSafari = true;
            inputType = 'text';
          }
        }
        this.inputType = inputType;
      },
    },
    value: {
      immediate: true,
      handler(value) {
        if (!this.isNumberTypeSafari) return;
        if (Number.isNaN(value)) {
          this.$emit('input');
        }
      },
    },
  },
  methods: {
    switchVisibilityPassword() {
      if (this.type !== 'password') return;
      this.isPasswordVisible = !this.isPasswordVisible;
      this.inputType = this.isPasswordVisible ? 'text' : 'password';
    },
  },
};
